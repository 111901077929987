var $actionBar, actionBarHeight, $actionBarParentBox, actionBarOffset;
const hasActionBar = document.getElementById("action-bar") != null;

if (hasActionBar) {
    $actionBar          = $("#action-bar");
    actionBarHeight     = $("#action-bar").height();
    $actionBarParentBox = $actionBar.parent();
    actionBarOffset     = $actionBar.offset().top + 40;
}

$(() => {
    if (actionBarOffset < 0) {
        actionBarOffset = 40;
    }

    if (hasActionBar) {
        enableStickyBar();
    }
});

function enableStickyBar() {
    $(window).on("scroll", function () {
        if (window.pageYOffset >= actionBarOffset) {
            $actionBar.addClass("sticky-action-bar");
            $actionBarParentBox.css("margin-top", actionBarHeight);
        } else {
            $actionBar.removeClass("sticky-action-bar");
            $actionBarParentBox.removeAttr("style");
        }
    });

    $(window).on("resize", function () {
        if (window.pageYOffset >= actionBarOffset) {
            $actionBar.addClass("sticky-action-bar");
            $actionBarParentBox.css("margin-top", actionBarHeight);
        } else {
            $actionBar.removeClass("sticky-action-bar");
            $actionBarParentBox.removeAttr("style");
        }
    });
}
